<template>
  <div>
    <p>
      Login successful<br />
      Your browser should be redirected soon...
    </p>
  </div>
</template>

<script>
import AuthSvc from "../services/AuthenticationService.js";
import Toaster from "../mixins/toaster.js";

export default {
  name: "LoginCallback",
  mixins: [Toaster],
  data() {
    return {};
  },
  async created() {
    try {
      const currentUser = await AuthSvc.signinRedirectCallback();
      //const currentUser = await AuthSvc.getuser();
      if (isNaN(currentUser)) {
        this.$router.push({ name: "adminHome" });
      } else {
        this.$router.push({ name: "userHome" });
      }
    } catch (e) {
      this.toast("danger", "Autenticazione", "Error " + e);
    }
  },
};
</script>
