<template>
  <div>
    <p>
      Logout successful<br />
      Your browser should be redirected soon...
    </p>
  </div>
</template>

<script>
import AuthenticationService from "../services/AuthenticationService.js";
import Toaster from "../mixins/toaster.js";

export default {
  name: "LogoutCallback",
  mixins: [Toaster],
  data() {
    return {};
  },
  async created() {
    try {
      await AuthenticationService.signoutRedirectCallback();
      this.$router.push({ name: "home" });
    } catch (e) {
      this.toast("danger", "Autenticazione", e);
    }
  },
};
</script>
